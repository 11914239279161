"use client";

import React from 'react';
import Link from 'next/link';
import FormattedDate from './formattedDate';

const HeaderTop = () => {
  return (
    <div className="w-full text-center text-gray-900 dark:text-gray-100 font-serif antialiased dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-center border-t border-gray-200 dark:border-gray-700">
          <Link href="/" className="text-2xl sm:text-5xl tracking-tight font-extrabold opacity-60 py-1 pt-2 sm:pt-4 w-auto hover:opacity-80 transition-opacity">
            Traded News
          </Link>
          <div className="text-xs sm:text-sm text-gray-400 w-full sm:w-[80%] sm:max-w-[400px] mx-auto">
            <FormattedDate date={new Date().toISOString()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
