import {PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {useFirebaseService} from "@/hooks/useFirebaseService";
import NewsInfo from "@entities/newsInfo";
import ItemInfo from "@entities/itemInfo";
import ProgressIndicator from './progress-indicator';

interface NewsLetterItemExProperties {
  itemInfo?: ItemInfo;
  newsInfo?: {
    title: string;
    description: string;
  };
}

const truncateText = (text: string, limit: number) => {
  if (!text) return '';
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  } else {
    return text;
  }
};

function NewsLetterItemEx(properties: PropsWithChildren<NewsLetterItemExProperties>): ReactElement {
  const {getNewsItemSnapshot} = useFirebaseService();
  const [newsInfo, setNewsInfo] = useState<any|null>(properties?.newsInfo || null);
  const [itemInfo, setItemInfo] = useState<ItemInfo|null>(properties?.itemInfo || null);
  const [isLoading, setIsLoading] = useState(properties?.newsInfo ? false : true);
  const [loadingProgress, setLoadingProgress] = useState(properties?.newsInfo ? 100 : 0);

  // Update itemInfo when properties change
  useEffect(() => {
    if (properties?.itemInfo) {
      setItemInfo(properties.itemInfo);
    }
  }, [properties?.itemInfo]);

  // Update newsInfo when properties change
  useEffect(() => {
    if (properties?.newsInfo) {
      setNewsInfo(properties.newsInfo);
      setIsLoading(false);
      setLoadingProgress(100);
    }
  }, [properties?.newsInfo]);

  // Fetch news data for stock items
  useEffect(() => {
    // Skip if we have direct newsInfo or no valid itemInfo
    if (properties?.newsInfo || !itemInfo || !itemInfo.symbol || itemInfo.symbol.length == 0) {
      if (!properties?.newsInfo) setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    // Simulate progress for better UX
    const progressInterval = setInterval(() => {
      setLoadingProgress(prev => {
        if (prev >= 90) {
          clearInterval(progressInterval);
          return prev;
        }
        return prev + 10;
      });
    }, 100);

    const unsubscribe = getNewsItemSnapshot(itemInfo.symbol, handleData, handleError);
    
    return () => {
      unsubscribe();
      clearInterval(progressInterval);
      setLoadingProgress(0);
    };
  }, [itemInfo?.symbol, properties?.newsInfo]);

  const handleData = (data: any) => {
    setNewsInfo(data as NewsInfo);
    setLoadingProgress(100);
    setTimeout(() => setIsLoading(false), 200); // Smooth transition
  }

  const handleError = (error: any) => {
    console.log("Error getting document data:", error);
    setIsLoading(false);
    setLoadingProgress(0);
  }

  const StockView = () => (
    <div className="flex flex-col items-center justify-center w-full bg-white dark:bg-gray-800 mb-2 rounded-2xl border border-gray-200/10 dark:border-gray-700/50 shadow-sm hover:shadow-md transition-shadow duration-200 relative overflow-hidden">
      <ProgressIndicator isLoading={isLoading} loadingProgress={loadingProgress} />
      <div className="font-sans text-sm text-gray-500 dark:text-gray-400 p-3 border-b border-gray-100 dark:border-gray-700 w-full flex flex-col justify-start">
        <div className="flex flex-col justify-center text-xs w-full">
          <div className="flex items-center justify-between w-full gap-2">
            <div className="font-medium text-gray-900 dark:text-gray-100 min-w-0 truncate">{itemInfo?.symbol || 'NA'}</div>
            <div className="border border-gray-200 dark:border-gray-700 rounded-full px-3 py-1 flex-shrink-0 text-right whitespace-nowrap bg-gray-50 dark:bg-gray-700">
              <span className="text-gray-900 dark:text-gray-100 font-medium">${Number(itemInfo?.price || 0).toFixed(2)}</span>{' '}
              <span className={`${Number(itemInfo?.changesPercentage || 0) >= 0 ? 'text-green-600 dark:text-green-500' : 'text-red-600 dark:text-red-500'}`}>
                ({Number(itemInfo?.changesPercentage || 0).toFixed(2)}%)
              </span>
            </div>
          </div>
          <div className="text-xs text-gray-400 dark:text-gray-500 mt-0.5 text-left truncate">
            {truncateText(itemInfo?.name || 'NA', 40)}
          </div>
        </div>
      </div>
      <div className="text-[15px] leading-relaxed text-gray-600 dark:text-gray-300 font-sans p-4 min-h-[100px] w-full overflow-hidden break-words">
        <div className="max-h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent pr-1">
          {newsInfo?.short || itemInfo?.description || 'No Description'}
        </div>
      </div>
    </div>
  );

  const NewsOnlyView = () => (
    <div className="flex flex-col items-center justify-center w-full bg-white dark:bg-gray-800 mb-2 rounded-2xl border border-gray-200/10 dark:border-gray-700/50 shadow-sm hover:shadow-md transition-shadow duration-200 relative overflow-hidden">
      <ProgressIndicator isLoading={isLoading} loadingProgress={loadingProgress} />
      <div className="font-sans text-sm text-gray-500 dark:text-gray-400 p-3 border-b border-gray-100 dark:border-gray-700 w-full flex flex-col justify-start">
        <div className="flex flex-col justify-center text-xs w-full">
          <div className="flex items-center justify-between w-full gap-2">
            <div className="font-medium text-gray-900 dark:text-gray-100 min-w-0 truncate">SEC: {truncateText(newsInfo?.title || '', 30)}</div>
          </div>
        </div>
      </div>
      <div className="text-[15px] leading-relaxed text-gray-600 dark:text-gray-300 font-sans p-4 min-h-[100px] w-full overflow-hidden break-words">
        <div className="max-h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent pr-1">
          {newsInfo?.description || 'No Description'}
        </div>
      </div>
    </div>
  );
  
  return properties.newsInfo ? NewsOnlyView() : StockView();
}

export default NewsLetterItemEx;
