'use client';

import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import CachingService from "@services/caching.service";
import SecRssFeedItem from "@entities/secRssFeedItem";
import { useFirebaseService } from '@/hooks/useFirebaseService';
import HeaderContainer from "@/components/header-container";
import RssAggregatorService from "@services/rssAggregator.service";
import NewsLetterContentWrapper from "@/components/newsletter-content-wrapper";
import SubscriptionStart from '@/components/subscription-start';
import { useAuth } from '@/contexts/authContext';
import UserInfo2 from '@/functions/src/entities/userInfo2';
import ListInfo from '@/functions/src/entities/listInfo';

// Dynamically import components that might use browser APIs
const Footer = dynamic(() => import('@/components/footer'), { ssr: false });

export default function Home() {
  const keyInTheNews = "data-in-the-news";
  const [news, setNews] = useState<SecRssFeedItem[]>([]);
  const [loading, setLoading] = useState(true);
  const { getListInfosForUser } = useFirebaseService();
  const [showSubscriptionStart, setShowSubscriptionStart] = useState(false);
  const [isAutoShown, setIsAutoShown] = useState(false);
  const { userInfo, isAuthStateChanging } = useAuth();
  const [user, setUser] = useState<UserInfo2 | null>(null);
  const [userListIds, setUserListIds] = useState<string[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [finalListIds, setFinalListIds] = useState<{id:string, caption:string}[]>([]);
  const [lists, setLists] = useState<ListInfo[]>([]);
  const [loadingLists, setLoadingLists] = useState(false);

  useEffect(() => {
    setShowSubscriptionStart(false);
  }, []);

  useEffect(() => {
    // Only proceed if auth state is not changing (loading complete)
    if (isAuthStateChanging) return;
    
    // Handle the case when user is not logged in
    if (!userInfo) {
      setUser(null);
      setShowSubscriptionStart(true);
      setIsAutoShown(true);
      return;
    }
    
    // Handle logged in user
    setUser(userInfo);
    
    const shouldShow = !userInfo.hasActiveSubscription;
    
    if (shouldShow) {
      setShowSubscriptionStart(true);
      setIsAutoShown(true); // Flag that this was automatically shown
    } else {
      setShowSubscriptionStart(false);
    }
  }, [userInfo, isAuthStateChanging]);
  
  // Function to handle animated hiding of the subscription modal
  const hideSubscriptionWithAnimation = () => {
    // Create a reference to the subscription container
    const subscriptionElement = document.querySelector('.subscription-container');
    
    // Add a class to trigger the fade-out animation
    if (subscriptionElement) {
      subscriptionElement.classList.add('hiding');
      
      // Actually hide the component after animation completes
      setTimeout(() => {
        setShowSubscriptionStart(false);
        // Remove the hiding class after the component is hidden
        subscriptionElement.classList.remove('hiding');
      }, 500); // Match this to the animation duration
    } else {
      // Fallback if element not found
      setShowSubscriptionStart(false);
    }
  };
  
  // Auto-hide subscription start component after 2 seconds with animation
  // But only if it was automatically shown, not manually triggered
  useEffect(() => {
    let hideTimer: NodeJS.Timeout;
    
    if (showSubscriptionStart && isAutoShown) {
      // Start hiding after 2 seconds, but only if auto-shown
      hideTimer = setTimeout(() => {
        hideSubscriptionWithAnimation();
        setIsAutoShown(false); // Reset the flag after hiding
      }, 2000);
    }
    
    return () => {
      // Clean up timer
      if (hideTimer) clearTimeout(hideTimer);
    };
  }, [showSubscriptionStart, isAutoShown]);

  // Fetch user's list subscriptions
  useEffect(() => {
    // Define listIds array that will be shared between components
    const standardListIds = [
      { id: 'TOP_LEADERS', caption: 'TOP LEADERS' }, { id: 'TOP_LOSERS', caption: 'TOP LOSERS' }
    ];

    if (!user) {
      setFinalListIds(standardListIds);
      return;
    };

    const fetchUserLists = async () => {
      try {
        setLoadingLists(true);
        const listInfos = await getListInfosForUser(user?.uid);
        
        if (listInfos.length > 0) {
          setLists(listInfos);
          
          // Create finalListIds from the user's personal lists
          const userListsFormatted = listInfos.map(list => ({
            id: list.id,
            caption: list.name
          }));
          setFinalListIds(userListsFormatted);
        } else {
          // If no valid personal lists, use standard lists
          setFinalListIds(standardListIds);
        }
        
        setLoadingLists(false);
      } catch (err) {
        console.error('Error fetching user lists:', err);
        setError(err as Error);
        setFinalListIds(standardListIds);
        setLoadingLists(false);
      }
    };

    fetchUserLists();
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        let cachedInTheNews = CachingService.getCache().get(keyInTheNews);
        let secInTheNews: SecRssFeedItem[] = [];

        if (cachedInTheNews && cachedInTheNews.length > 0) {
          secInTheNews = cachedInTheNews;
        } else {
          let rssAggregator = new RssAggregatorService();
          secInTheNews = await rssAggregator.getRssFeed();
          if (secInTheNews && secInTheNews.length > 0) {
            CachingService.getCache().set(keyInTheNews, secInTheNews);
          }
        }

        console.log("Fetched SEC news, count:", secInTheNews.length);
        setNews(secInTheNews);
      } catch (error) {
        console.error('Error fetching SEC news:', error);
      } finally {
        setLoading(false);
      }
    })();
  }, [keyInTheNews]);

  if (loading) {
    return <div>Loading news...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* Fixed Header */}
      <HeaderContainer listIds={finalListIds} />
      {/* Scrollable Content */}
      <main className="flex-grow bg-gray-50 dark:bg-gray-900">
        <NewsLetterContentWrapper listIds={finalListIds} secInTheNews={news} />

        {showSubscriptionStart && (
          <SubscriptionStart 
            isOpen={showSubscriptionStart} 
            onClose={hideSubscriptionWithAnimation} 
          />
        )}
      </main>
      {/* Fixed Footer */}
      <Footer 
        onSubscribeClick={() => {
          setIsAutoShown(false); // Mark as manually shown
          setShowSubscriptionStart(true);
        }}
        showSubscribeButton={!showSubscriptionStart}
      />
    </div>
  );
}