"use client";

import { useState } from "react";
import { SubscriptionContainer } from "./subscription-container";
import { SubscriptionIntro } from "./subscription-intro";
import { useRouter } from "next/navigation"

interface SubscriptionStartProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SubscriptionStart({ isOpen, onClose }: SubscriptionStartProps) {
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState(false);
  const router = useRouter()

  const handleGetFreeEdition = () => {
    console.log(`Signing up for free edition with email: ${email}`);
  };

  const handleSelectPlan = () => {
    router.push("/plans")
  };

  return (
    <SubscriptionContainer isOpen={isOpen} onClose={onClose}>
      <SubscriptionIntro
        email={email}
        agree={agree}
        onEmailChange={setEmail}
        onAgreeChange={setAgree}
        onSelectPlan={handleSelectPlan}
        onGetFreeEdition={handleGetFreeEdition}
      />
    </SubscriptionContainer>
  );
}
