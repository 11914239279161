"use client";

import { cn } from "@/lib/utils";
import { useState, useEffect } from "react";
import { useAuth } from "@/contexts/authContext";
import { useFirebaseService } from "@/hooks/useFirebaseService";


interface SubscriptionContainerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function SubscriptionContainer({ isOpen, onClose, children }: SubscriptionContainerProps) {
  const { userInfo } = useAuth();
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const {getUserByEmail} = useFirebaseService();

  // Clear inputs when popup opens
  useEffect(() => {
    if (isOpen) {
      setEmail("");
      setAgree(false);
      setShowPlans(false);
      setShowSignUp(false);
    }
  }, [isOpen]);

  const onSelectPlan = async () => {
    // const userInfo = getUserInfo();
    // console.log('User info:', userInfo);
    // const isInvalidOrAnonymous = !userInfo || userInfo.isAnonymous;
    // console.log('Is user invalid or anonymous:', isInvalidOrAnonymous);

    const userAccount = await getUserByEmail(email);
    // console.log("=========================================="); 
    // console.log('Email:', email , 'User Account:', userAccount, ' Is a stripe customer:', userAccount?.stripeCustomerId??false);
    // console.log("==========================================");    
    const isValidUser = userAccount !== null;

    if (!isValidUser) {
      console.log(`Invalid user selected we will offer signup`);
      setShowSignUp(true);
      return false;
    }
    return isValidUser;
  };

  const handleSubscribe = async (plan: 'basic' | 'pro') => {
    const isValidUser = await onSelectPlan();
    if (isValidUser) {
      console.log(`Valid user selected ${plan} plan`);
    }
  };

  const handleGetFreeEdition = () => {
    console.log(`Signing up for free edition with email: ${email}`);
  };

  return (
    <div className={cn(
      "subscription-container fixed inset-x-0 bottom-[49px] z-40 transform transition-all duration-500 ease-in-out px-4 sm:px-6 lg:px-8",
      isOpen ? "translate-y-0 opacity-100" : "translate-y-full opacity-0",
      // Add a hiding class that can be toggled via JavaScript
    )}>
      <div 
        className={cn(
          "relative w-full max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-t-xl border-2 border-gray-400 dark:border-gray-600 overflow-hidden flex flex-col transition-all duration-500 ease-in-out",
          isOpen ? "shadow-2xl scale-100" : "shadow-none scale-95"
        )} 
        style={{ minHeight: '450px', maxHeight: '90vh', overflowY: 'auto' }}
      >
        {/* Header */}
        <div className="px-6 py-3 flex justify-between items-center border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
          <div className="flex items-center space-x-3">
            {showPlans && (
              <button
                onClick={() => setShowPlans(false)}
                className="inline-flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors"
              >
                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                <span className="text-sm">Back</span>
              </button>
            )}
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content Section - Scrollable */}
        <div className="flex flex-col flex-grow overflow-y-auto">
          {children}
        </div>
      </div>
    </div>

  );
}
