import React, {PropsWithChildren, ReactElement, useEffect, useState} from 'react';

import NewsLetterItemEx from "@/components/news-letter-itemex";
import {useFirebaseService} from "@/hooks/useFirebaseService";
import ListInfo from "@entities/listInfo";
import ItemInfo from '@entities/itemInfo';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface NewsLetterItemListProperties {
  listId: string;
  caption: string;
  onPrev?: () => void;
  onNext?: () => void;
  isFirst?: boolean;
  isLast?: boolean;
  currentPage?: number;
  totalPages?: number;
  sectionIndex?: number;
  totalSections?: number;
}

function NewsLetterItemList(properties: PropsWithChildren<NewsLetterItemListProperties>):ReactElement {
  const {getListInfo, getItemsSnapshot} = useFirebaseService();
  const [items, setItems] = useState<ItemInfo[]>([]);
  const [listInfo, setListInfo] = useState<ListInfo|null>(null);
  const [caption, setCaption] = useState(properties.caption)
  const {listId} = properties;

  useEffect(() => {
    if(!listId) return;

    const fetchListInfo = async () => {
      const listInfo = await getListInfo(listId);
      setListInfo(listInfo);
    }

    fetchListInfo();
  }, [listId]);

  useEffect(() => {
    if(!listInfo) return;

    const handleData = (data: any[]) => {
      let finalItems: ItemInfo[] = data || [];
      if(listId == "TOP_LEADERS"){
        finalItems = finalItems.filter(item => item.changesPercentage !== undefined && item.changesPercentage > 0);
      } 

      if(listId == "TOP_LOSERS"){
        finalItems = finalItems.filter(item => item.changesPercentage !== undefined && item.changesPercentage < 0);
      }
      setItems(finalItems);
    }
  
    const handleError = (error: any) => {
      console.log("Error getting document data:", error);
    }

    const unsubscribe = getItemsSnapshot(listInfo.items || [], handleData, handleError);
    return () => {
      unsubscribe();
    };
  }, [listInfo]);

  const handlePrev = () => {
    if (properties.onPrev) {
      properties.onPrev();
    }
  };

  const handleNext = () => {
    if (properties.onNext) {
      properties.onNext();
    }
  };
  
  const { 
    isFirst = false, 
    isLast = false, 
    currentPage = 0, 
    totalPages = 1,
    sectionIndex = 0,
    totalSections = 1
  } = properties;

  return <div className="flex flex-col min-w-0 overflow-hidden mb-4">
    <div className="font-inter text-base font-semibold text-gray-700 dark:text-yellow-400 p-3 mb-2 w-full pb-1 flex items-center justify-between h-10">
      <div className="w-9 h-9 flex items-center justify-center">
        {isFirst && currentPage > 0 ? (
          <button 
            onClick={handlePrev} 
            className="p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none transition-colors hidden sm:flex items-center justify-center"
            aria-label="Previous list"
          >
            <ChevronLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
          </button>
        ) : (
          <div className="w-6 h-6 hidden sm:block"></div>
        )}
      </div>
      <div className="flex flex-1 items-center w-full relative">
        <span className="font-semibold truncate absolute left-1/2 transform -translate-x-1/2 text-center">{caption || 'NA'}</span>
        <span className="text-xs text-gray-500 dark:text-gray-400 ml-auto flex-shrink-0">{sectionIndex + 1}/{totalSections}</span>
      </div>
      <div className="w-9 h-9 flex items-center justify-center">
        {isLast && currentPage < totalPages - 1 ? (
          <button 
            onClick={handleNext} 
            className="p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none transition-colors hidden sm:flex items-center justify-center"
            aria-label="Next list"
          >
            <ChevronRight className="h-6 w-6 text-gray-600 dark:text-gray-400" />
          </button>
        ) : (
          <div className="w-6 h-6 hidden sm:block"></div>
        )}
      </div>
    </div>
    <div className="space-y-3">
      {items.map(item => <NewsLetterItemEx key={item.id} itemInfo={item}></NewsLetterItemEx>)}
    </div>
    {/* Extra space at the bottom */}
    <div className="h-4"></div>
  </div>
}

export default NewsLetterItemList;
