export default class UserInfo2 {
  displayName: string;
  providerId: string;
  email: string;
  uid: string;
  isAnonymous: boolean;
  photoURL: string | null; 
  documentProcessedTotal: number = 0;
  documentProcessedLastDay: number = 0;
  documentProcessedLastDate: Date = new Date();
  lastLogin: Date = new Date();
  businessName: string = '';
  businessAddress: string = '';
  createdOn: Date = new Date();
  modifiedOn: Date = new Date();
  coupon: string = '';
  documentProcessedLastDayDate?: Date = new Date();
  lists: string[] = [];
  pdfUrl?: string;
  emailBody?: any;
  emailBodyUpdatedOnL?: number; // Timestamp (milliseconds) when email body was last updated
  stripeCustomerId?: string;
  hasActiveSubscription: boolean;
  isAdmin: boolean = false;
  emailVerified: boolean = false;
  sendReport: boolean = false; // Flag to indicate if a newsletter should be sent to this user
  mergeReservedList: boolean = false; // Flag to indicate if reserved lists should be merged with user lists
  
  constructor(user: any) {
    this.displayName = user.displayName || (user.providerData[0]?.displayName);
    this.providerId = user.providerData[0]?.providerId || '';
    this.email = user.email || user.providerData[0]?.email || '';
    this.uid = user.uid;
    this.isAnonymous = user.isAnonymous || false;
    this.photoURL = user.photoURL || user.providerData[0]?.photoURL || null;
    // Preserve isAdmin if it exists in the user data
    this.isAdmin = user.isAdmin || false;
    // Set email verification status from Firebase Auth
    this.emailVerified = user.emailVerified || false;
  }
}
