"use client";

import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  signInAnonymously,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  UserCredential,
  AuthError,
  sendPasswordResetEmail,
  deleteUser,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential
} from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import { useRouter } from "next/navigation";
import UserInfo2 from "@entities/userInfo2";
import { auth } from "@/hooks/firebase.client.config";
import { useSignInWithEmailAndPassword, useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useFirebaseService } from "@/hooks/useFirebaseService";
/**
 * Interface that will be available to all the enclosed components
 */
interface AuthContextType {
  userInfo: UserInfo2 | null;
  isAuthStateChanging: boolean;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo2 | null>>;
  signUpWithEmailAndPassword: (email: string, password: string, productId?: string) => Promise<UserCredential|null>;
  signInWithEmailAndPassword: (email: string, password: string, productId?: string) => Promise<UserCredential|null>;
  sendResetEmail: (email: string) => Promise<void>;
  deleteAccount: () => Promise<void>;
  
  // Email verification methods
  sendVerificationEmail: () => Promise<void>;
  isEmailVerified: () => boolean;
  checkEmailVerificationStatus: () => Promise<boolean>;
  reauthenticateUser: (password: string) => Promise<boolean>;

  clearAuthError: () => void;
  logOut: () => void;
  anonymousSignIn: () => void;
  handleSignInFailure: (error: any) => Promise<void>;
  checkNoAnonymousAllowed: () => boolean;
  checkNoAnonymousAllowedWithReDirect: (redirectPath: string, productId?: string) => boolean;
  
  // Product ID management for free-to-paid upgrade flow
  getStoredProductId: () => string | null;
  storeProductId: (productId: string) => void;
  clearStoredProductId: () => void;
  
  signInLoading: boolean;
  signInError: AuthError | undefined;
  createUserLoading: boolean;
  createUserError: AuthError | undefined;
}

/**
 * Create the context
 */
const AuthContext = createContext<AuthContextType | null>(null);
/**
 * Create the provider
 */
export const AuthContextProvider = (props: PropsWithChildren) => {
  const [userInfo, setUserInfo] = useState<UserInfo2 | null>(null);
  const [isAuthStateChanging, setIsAuthStateChanging] = useState(false);
  // State for storing product ID during authentication flow
  const [storedProductId, setStoredProductId] = useState<string | null>(null);
  const router = useRouter();
  const [
    signInWithEmailAndPasswordHook,
    userCredSignIn,
    signInLoading,
    signInError
  ] = useSignInWithEmailAndPassword(auth);

  const [
    createUserWithEmailAndPasswordHook,
    userCredSignUp,
    createUserLoading,
    createUserError
  ] = useCreateUserWithEmailAndPassword(auth);

  const fbService = useFirebaseService();

  useEffect(() => {
    // Set persistence to LOCAL
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to LOCAL');
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });

    // Listen to auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user?.email);
      setIsAuthStateChanging(true);
      try {
        if (user) {
          // Step 1: Create basic user info
          const basicUser = new UserInfo2(user);
          // Step 2: Enrich with additional data
          const enrichedUser = await fbService.enrichUserInfo(basicUser);
          setUserInfo(enrichedUser);
        } else {
          setUserInfo(null);
        }
      } finally {
        setIsAuthStateChanging(false);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const anonymousSignIn = async () => {
    const result = await signInAnonymously(auth);
    // setRawUser(result.user);
    console.log(`Anonymous SignIn result:`, result);
    // Store the anonymous user info
    const anonymousUserInfo = new UserInfo2(result.user);
    setUserInfo(anonymousUserInfo);
    localStorage.setItem('userInfo', JSON.stringify(anonymousUserInfo));
  }

  const handleSignInFailure = async (error: any): Promise<void> => {
    // Handle sign-in failure here...
    // await signOut(auth);
    await logOut();
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      setUserInfo(null);
      clearStoredProductId(); // Clear any stored product ID on logout
      router.push('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  const checkNoAnonymousAllowed = (): boolean => {
    // Using userInfo directly instead of through getUserInfo
    const isInvalidOrAnonymous = !userInfo || (userInfo && userInfo.isAnonymous);
    if (isInvalidOrAnonymous) {
      router.push(`/signin`);
    }
    return isInvalidOrAnonymous;
  }

  const checkNoAnonymousAllowedWithReDirect = (redirectPath: string, productId?: string): boolean => {
    // Using userInfo directly instead of through getUserInfo
    const isInvalidOrAnonymous = !userInfo || userInfo.isAnonymous;
    if (isInvalidOrAnonymous) {
      let redirectUrl = `/signin/${encodeURIComponent(redirectPath)}`;
      // If product ID is provided, store it and add it to the URL
      if (productId) {
        storeProductId(productId);
        redirectUrl += `?productId=${encodeURIComponent(productId)}`;
      }
      router.push(redirectUrl);
    }
    return isInvalidOrAnonymous;
  }
  
  // Product ID management functions
  const getStoredProductId = (): string | null => {
    return storedProductId;
  }
  
  const storeProductId = (productId: string): void => {
    setStoredProductId(productId);
  }
  
  const clearStoredProductId = (): void => {
    setStoredProductId(null);
  }

  const signUpWithEmailAndPassword = async (email: string, password: string, productId?: string) : Promise<UserCredential|null> => {
    try {
      // If productId is provided, store it for later use
      if (productId) {
        storeProductId(productId);
      } else {
        // Clear any previously stored product ID to ensure clean state
        clearStoredProductId();
      }
      
      const result = await createUserWithEmailAndPasswordHook(email, password);
      if (result && result.user) {
        // Send verification email after successful sign up
        await sendEmailVerification(result.user);
      }
      return result || null;
    } catch (error) {
      console.error("Error during sign up:", error);
      throw error;
    }
  }

  const clearAuthError = () => {
    // Directly reset the error states by calling the hooks with a force reset parameter
    // This is a workaround since the hooks don't provide a direct way to clear errors
    try {
      // @ts-ignore - Accessing internal state of the hooks
      if (typeof signInWithEmailAndPasswordHook[3] === 'function') {
        // @ts-ignore - Call the reset function if available
        signInWithEmailAndPasswordHook[3]();
      }
      
      // @ts-ignore - Accessing internal state of the hooks
      if (typeof createUserWithEmailAndPasswordHook[3] === 'function') {
        // @ts-ignore - Call the reset function if available
        createUserWithEmailAndPasswordHook[3]();
      }
      
      console.log('Auth errors cleared');
    } catch (error) {
      console.error('Failed to clear auth errors:', error);
    }
  };

  /**
   * Utility function to check if a user is an admin
   */
  const isUserAdmin = async (uid: string): Promise<boolean> => {
    try {
      // Get Firestore instance
      const db = getFirestore();
      
      // Get user document from Firestore
      const userDoc = await getDoc(doc(db, 'users', uid));
      
      // Check if user exists and has admin privileges
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return userData?.isAdmin === true;
      }
      
      return false;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false; // Default to non-admin on error
    }
  };

  /**
   * Sign in with email and password
   * For admin users: bypass email verification check
   * For regular users: enforce email verification
   * Optionally accepts a productId for the free-to-paid upgrade flow
   * Works normally without productId for standard sign-in
   */
  const signInWithEmailAndPassword = async (email: string, password: string, productId?: string) : Promise<UserCredential|null> => {
    try {
      // If productId is provided, store it for later use
      if (productId) {
        storeProductId(productId);
      } else {
        // Clear any previously stored product ID to ensure clean state
        clearStoredProductId();
      }
      
      // 1. First authenticate with Firebase
      const result = await signInWithEmailAndPasswordHook(email, password);
      if (!result) {
        throw new Error('Sign in failed');
      }
      
      // 2. Check if email is verified
      if (!result.user.emailVerified) {
        // 3. Check if user is an admin (admins bypass verification)
        const isAdmin = await isUserAdmin(result.user.uid);
        
        // 4. If not admin and not verified, enforce verification
        if (!isAdmin) {
          // Sign out the user
          await signOut(auth);
          throw new Error('Please verify your email before signing in. Check your inbox for a verification link.');
        } else {
          console.log('Admin user detected - bypassing email verification');
        }
      }
      
      // 5. Return successful result
      return result;
    } catch (error: any) {
      throw error;
    }
  };

  const sendResetEmail = async (email: string): Promise<void> => {
    try {
      console.log('Sending password reset email to:', email);
      console.log('Using APP_URL:', process.env.NEXT_PUBLIC_APP_URL);
      
      // Use a simpler configuration without handleCodeInApp
      const actionCodeSettings = {
        url: `${process.env.NEXT_PUBLIC_APP_URL}/reset-password`
        // Removed handleCodeInApp: true as it requires special configuration
      };
      
      console.log('Action code settings:', actionCodeSettings);
      console.log('Firebase auth instance available:', !!auth);
      
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      console.log('Password reset email sent successfully');
    } catch (error: any) {
      console.error('Error sending password reset email:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      throw error;
    }
  };

  /**
   * Helper function to clean up user data in Firestore
   * @param uid User ID to clean up
   */
  const cleanupUserDataInFirestore = async (uid: string): Promise<void> => {
    try {
      // First check if the user exists in Firestore
      const checkUserFn = fbService.getHttpsCallable('checkUser');
      const checkResult:any = await checkUserFn({ uid });
      // console.log("============================================================")
      // console.log("Check User Result:", checkResult)
      // console.log("============================================================")
      // console.log("User exists in Firestore:", checkResult.data.exists);
      
      // Call the cleanupUserData Cloud Function regardless of whether user exists
      // The function will handle the case where the user doesn't exist
      const cleanupUserDataFn = fbService.getHttpsCallable('cleanupUserData');
      const result:any = await cleanupUserDataFn({ uid });
      // console.log("============================================================")
      // console.log("Cleanup Result:", result)
      // console.log("============================================================")

      if (!result.data.success) {
        // console.error('Error cleaning up user data:', result.data.message);
        // Continue with account deletion even if cleanup fails
      }
    } catch (firestoreError) {
      console.error('Error during user data cleanup process:', firestoreError);
      // Continue with account deletion even if Firestore deletion fails
    }
  };



  /**
   * Deletes the user account and associated data
   */
  const deleteAccount = async (): Promise<void> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently signed in');
      }

      // First, call the Cloud Function to clean up user data in Firestore
      if (userInfo?.uid) {
        await cleanupUserDataInFirestore(userInfo.uid);
      }

      // Delete the user account from Firebase Authentication
      await deleteUser(currentUser);
      await logOut();
      
      // Clear local state
      setUserInfo(null);
      
      // Redirect to home page
      router.push('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  };

  /**
   * Sends a verification email to the currently signed-in user
   */
  const sendVerificationEmail = async (): Promise<void> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently signed in');
      }
      
      await sendEmailVerification(currentUser);
    } catch (error) {
      console.error('Error sending verification email:', error);
      throw error;
    }
  };

  /**
   * Checks if the current user's email is verified
   */
  const isEmailVerified = (): boolean => {
    const currentUser = auth.currentUser;
    return currentUser?.emailVerified || false;
  };

  /**
   * Reloads the current user and checks verification status
   */
  const checkEmailVerificationStatus = async (): Promise<boolean> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        return false;
      }
      
      await currentUser.reload();
      return currentUser.emailVerified;
    } catch (error) {
      console.error('Error checking email verification status:', error);
      return false;
    }
  };

  /**
   * Reauthenticates the current user with their password
   * Useful before sensitive operations like sending verification emails
   */
  const reauthenticateUser = async (password: string): Promise<boolean> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.email) {
        return false;
      }
      
      const credential = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, credential);
      return true;
    } catch (error) {
      console.error('Error reauthenticating user:', error);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        isAuthStateChanging,
        logOut,
        anonymousSignIn,
        handleSignInFailure,
        setUserInfo,
        checkNoAnonymousAllowed,
        checkNoAnonymousAllowedWithReDirect,
        clearAuthError,
        signUpWithEmailAndPassword,
        signInWithEmailAndPassword,
        sendResetEmail,
        deleteAccount,
        sendVerificationEmail,
        isEmailVerified,
        checkEmailVerificationStatus,
        reauthenticateUser,
        getStoredProductId,
        storeProductId,
        clearStoredProductId,
        signInLoading,
        signInError,
        createUserLoading,
        createUserError
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

/**
 * Custom hook to use the AuthContext
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};
