"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import { useToast } from "@/hooks/use-toast";
import { functions } from "@/hooks/firebase.client.config";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "@/contexts/authContext";
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface SubscriptionIntroProps {
  email: string;
  agree: boolean;
  onEmailChange: (value: string) => void;
  onAgreeChange: (checked: boolean) => void;
  onSelectPlan: () => void;
  onGetFreeEdition: () => void;
}

interface ConfirmFreeUserResponse {
  status: 'created' | 'verification_sent' | 'already_verified';
  message?: string;
}

function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function SubscriptionIntro({
  email,
  agree,
  onEmailChange,
  onAgreeChange,
  onSelectPlan,
  onGetFreeEdition,
}: SubscriptionIntroProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const { userInfo } = useAuth();
  const confirmFreeUser = httpsCallable<{ email: string }, ConfirmFreeUserResponse>(functions, 'confirmFreeUser');

  const handleGetFreeEdition = async () => {
    if (!email || !isValidEmail(email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address",
        variant: "destructive",
      });
      return;
    }

    if (!agree) {
      toast({
        title: "Error",
        description: "Please agree to the privacy policy",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const result = await confirmFreeUser({ email });

      switch (result.data.status) {
        case 'created':
          toast({
            title: "Success",
            description: "Please check your email for verification",
            variant: "default",
          });
          onGetFreeEdition();
          break;
        case 'verification_sent':
          toast({
            title: "Verification Email Sent",
            description: "Please check your email to verify your account",
            variant: "default",
          });
          break;
        case 'already_verified':
          toast({
            title: "Already Registered",
            description: "This email is already registered. Please sign in to access your account.",
            variant: "default",
          });
          break;
      }

      // Reset form fields after successful submission
      onEmailChange('');
      onAgreeChange(false);
    } catch (error: any) {
      console.error('Error confirming free user:', error);
      toast({
        title: "Error",
        description: error?.message || "Failed to process your request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const leftHandContent = (
    <Card className="flex flex-col h-full dark:bg-gray-900">
      <CardHeader>
        <CardTitle className="dark:text-gray-200">Free Newsletter</CardTitle>
        <CardDescription className="dark:text-gray-600">Stay informed with our free newsletter.</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow space-y-4">
        <ul className="space-y-2 text-left mb-4 px-1 dark:bg-gray-900">
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <CheckCircleIcon className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Market commentary with AI</span>
          </li>
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <CheckCircleIcon className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Includes reader friendly PDF</span>
          </li>
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <CheckCircleIcon className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Delivered once a day</span>
          </li>
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <CheckCircleIcon className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">One-click unsubscribe</span>
          </li>
        </ul>

        {!userInfo && (
          <div className="space-y-4">
            <Input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => onEmailChange(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            />

            <div className="flex items-start space-x-2">
              <Checkbox
                id="terms"
                checked={agree}
                onCheckedChange={(checked) => onAgreeChange(checked as boolean)}
              />
              <label
                htmlFor="terms"
                className="text-xs text-gray-500 dark:text-gray-400 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I agree to receive emails and accept the <a href="/privacy" className="text-blue-500 dark:text-blue-400 hover:underline">Privacy Policy</a>
              </label>
            </div>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex justify-start">
        {!userInfo ? (
          <Button
            className={cn(
              "w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold h-10 text-base rounded-md shadow-sm transition-all duration-200",
              !agree && "opacity-50 cursor-not-allowed"
            )}
            disabled={!agree || isSubmitting}
            onClick={handleGetFreeEdition}
          >
            Get Free Edition
          </Button>
        ) : (
          <div className="flex items-center justify-center text-green-600 dark:text-green-500 font-medium p-2 border border-green-500 dark:border-green-600 rounded-xl h-10 w-full">
            <CheckCircleIcon className="mr-2 h-5 w-5" />
            <span>You are subscribed</span>
          </div>
        )}
      </CardFooter>
    </Card>
  );

  const rightHandContent = (
    <Card className="flex flex-col h-full dark:bg-gray-900">
      <CardHeader>
        <CardTitle className="dark:text-gray-200">Premium Subscription</CardTitle>
        <CardDescription className="dark:text-gray-600">Get personalized news that matters to you.</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow space-y-4">
        <ul className="space-y-2 text-left mb-4 px-1 dark:bg-gray-900">
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <CheckCircleIcon className="mr-3 h-5 w-5 text-gray-500 dark:text-gray-400 mt-0.5 flex-shrink-0" />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300 underline">Everything in Free Edition</span>
          </li>
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <svg className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
            </svg>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Customized news summary with AI</span>
          </li>
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <svg className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
            </svg>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Create your own watchlists</span>
          </li>
          <li className="flex items-start p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors">
            <svg className="mr-3 h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
            </svg>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Upto 3 schedulable deliveries</span>
          </li>
        </ul>
      </CardContent>
      <CardFooter className="flex justify-start">
        <Button
          className="w-full bg-gray-900 hover:bg-black dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-semibold h-10 text-base rounded-md shadow-sm transition-all duration-200 
          dark:border dark:border-gray-300"
          onClick={onSelectPlan}
        >
          Select A Plan
        </Button>
      </CardFooter>
    </Card>
  );

  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8 dark:bg-gray-800">
      <h1 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-8 text-center">
        Unlock news that matters
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
        {leftHandContent}
        {rightHandContent}
      </div>
      <p className="mt-8 text-center text-gray-600 dark:text-gray-400 text-sm">
        By subscribing, you agree to our <a href="/terms" className="text-blue-600 dark:text-blue-400 font-medium hover:underline">Terms of Service</a>.
      </p>
    </div>
  );
}