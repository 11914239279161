import SecRssFeedItem from "@entities/secRssFeedItem";
import NewsLetterItemEx from "@/components/news-letter-itemex";
import {PropsWithChildren, ReactElement, useState, useCallback, useEffect} from 'react';
import NewsLetterItemList from "@/components/news-letter-item-list";
import ListNav from "@/components/list-nav";
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface NewsLetterContentWrapperProperties {
  secInTheNews: SecRssFeedItem[];
  listIds: {
    id: string;
    caption: string;
  }[];
}

function NewsLetterContentWrapper(props: PropsWithChildren<NewsLetterContentWrapperProperties>):ReactElement {
  const [inTheNews, setInTheNews] = useState<SecRssFeedItem[]>(props.secInTheNews || []);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const listsPerPage = 3;
  
  // Create a combined array of all content sections
  const allSections = [
    ...props.listIds,
    { id: 'in-the-news', caption: 'IN THE NEWS' }
  ];
  
  // Calculate total pages based on section count
  useEffect(() => {
    const sectionCount = allSections.length;
    // Total pages is now the number of possible starting positions
    // which is (total sections - visible sections + 1)
    setTotalPages(Math.max(1, sectionCount - listsPerPage + 1));
  }, [allSections, listsPerPage]);
  
  // Get visible section indices
  const getVisibleIndices = useCallback(() => {
    const startIdx = currentPage;
    const endIdx = startIdx + listsPerPage;
    return { startIdx, endIdx };
  }, [currentPage, listsPerPage]);
  
  // Handle navigation - move by one list at a time
  const goToNextList = useCallback(() => {
    if (currentPage < allSections.length - listsPerPage) {
      setCurrentPage(prev => prev + 1);
    }
  }, [currentPage, allSections.length, listsPerPage]);
  
  const goToPrevList = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  }, [currentPage]);
  

  
  // Get current sections to display
  const getCurrentSections = useCallback(() => {
    const { startIdx, endIdx } = getVisibleIndices();
    return allSections.slice(startIdx, endIdx);
  }, [getVisibleIndices, allSections]);
  
  const currentSections = getCurrentSections();
  const showNavigation = allSections.length > listsPerPage;

  return (
    <div className="flex-1 overflow-hidden">
      <div className="h-full overflow-y-auto px-4 py-2 pb-16 space-y-4 scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent">
        {/* <ListNav
          currentPage={currentPage}
          totalPages={totalPages}
          listsPerPage={listsPerPage}
          allSectionsLength={allSections.length}
          onPrevClick={goToPrevList}
          onNextClick={goToNextList}
        /> */}
        
        {/* Desktop view with pagination */}
        <div className="hidden sm:grid sm:grid-cols-3 gap-4">
          {currentSections.map((section, index) => (
            <div key={section.id} className="min-w-0 overflow-hidden">
              {section.id === 'in-the-news' ? (
                <div className="flex flex-col min-w-0 overflow-hidden">
                  <div className="font-inter text-base font-semibold text-gray-700 dark:text-yellow-400 p-3 mb-2 w-full pb-1 flex items-center justify-between h-10">
                    <div className="w-9 h-9 flex items-center justify-center">
                      {index === 0 && currentPage > 0 ? (
                        <button 
                          onClick={goToPrevList} 
                          className="p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none transition-colors flex items-center justify-center"
                          aria-label="Previous list"
                        >
                          <ChevronLeft className="h-6 w-6 text-gray-600 dark:text-gray-400" />
                        </button>
                      ) : (
                        <div className="w-6 h-6"></div>
                      )}
                    </div>
                    <div className="flex flex-1 items-center w-full relative">
                      <span className="font-semibold truncate absolute left-1/2 transform -translate-x-1/2 text-center">{section.caption}</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 ml-auto flex-shrink-0">{currentPage + index + 1}/{allSections.length}</span>
                    </div>
                    <div className="w-9 h-9 flex items-center justify-center">
                      {index === currentSections.length - 1 && currentPage < totalPages - 1 ? (
                        <button 
                          onClick={goToNextList} 
                          className="p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none transition-colors flex items-center justify-center"
                          aria-label="Next list"
                        >
                          <ChevronRight className="h-6 w-6 text-gray-600 dark:text-gray-400" />
                        </button>
                      ) : (
                        <div className="w-6 h-6"></div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-2">
                    {inTheNews.map((item: SecRssFeedItem) => (
                      <NewsLetterItemEx 
                        key={item.title}
                        newsInfo={{
                          title: item.title,
                          description: item.description
                        }}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <NewsLetterItemList 
                  listId={section.id} 
                  caption={section.caption} 
                  onPrev={goToPrevList} 
                  onNext={goToNextList}
                  isFirst={index === 0}
                  isLast={index === currentSections.length - 1}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  sectionIndex={currentPage + index}
                  totalSections={allSections.length}

                />
              )}
            </div>
          ))}
        </div>
        
        {/* Mobile view with all sections */}
        <div className="grid grid-cols-1 gap-4 sm:hidden">
          {allSections.map((section, index) => (
            <div key={section.id} className="min-w-0 overflow-hidden">
              {section.id === 'in-the-news' ? (
                <div className="flex flex-col min-w-0 overflow-hidden">
                  <div className="font-inter text-base font-semibold text-gray-700 dark:text-yellow-400 p-3 mb-2 w-full pb-1 flex items-center justify-between h-10">
                    <div className="w-9 h-9"></div>
                    <div className="flex flex-1 items-center w-full relative">
                      <span className="font-semibold truncate absolute left-1/2 transform -translate-x-1/2 text-center">{section.caption}</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 ml-auto flex-shrink-0">{index + 1}/{allSections.length}</span>
                    </div>
                    <div className="w-9 h-9"></div>
                  </div>
                  <div className="space-y-2">
                    {inTheNews.map((item: SecRssFeedItem) => (
                      <NewsLetterItemEx 
                        key={item.title}
                        newsInfo={{
                          title: item.title,
                          description: item.description
                        }}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <NewsLetterItemList 
                  listId={section.id} 
                  caption={section.caption} 
                  sectionIndex={index}
                  totalSections={allSections.length}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsLetterContentWrapper;
