"use client";

import React, { useEffect, useState } from 'react';

interface Props {
  date: string | number;
}

const FormattedDate: React.FC<Props> = ({ date }) => {
  const [locale, setLocale] = useState('en-US'); // Default locale
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setLocale(window.navigator.language);
    setMounted(true);
  }, []);

  if (!mounted) {
    return null; // Return null on server-side
  }

  const d = new Date(date);
  return (
    <div>
      {new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(d)}
    </div>
  );
};

export default FormattedDate;
